<template>
  <v-container>
    <v-row>
      <v-col sm="12" lg="6">
        <h1><span v-html="$t('mhw_infopage_title')" /></h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" lg="6">
        <span v-html="$t('mhw_infopage_body')" />
      </v-col>
    </v-row>
  </v-container>
</template>
